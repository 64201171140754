import api from '@apiInstance';

export default {
  add(body) {
    return api.post('sales_invoice_headers', body);
  },
  get(id) {
    return api.fetch(`sales_invoice_headers/${id}`);
  },
  async list(params) {
    return await api.fetch('/sales_invoice_headers', { params });
  },
  update(body) {
    return api.patch('sales_invoice_headers', body);
  },
  delete(id) {
    return api.remove('sales_invoice_headers', id);
  },
};
